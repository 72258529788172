<template>
  <div>
    <v-tabs v-model="tabs">
      <v-tab href="#ativos">Ativos</v-tab>
      <v-tab href="#naoativos">Não ativos</v-tab>
      <v-tabs-items v-model="tabs">
        <v-tab-item value="ativos">
          <div class="mt-4">
            <v-card-title v-if="canList">
              Filtro
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisa" single-line
                hide-details></v-text-field>
            </v-card-title>
            <v-data-table :headers="headers" :items="itensLaudoCheckList" :search="search" sort-by="description" class="border">
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-toolbar-title>Itens Laudo Checklist registrados</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-icon v-if="canList" title="Atualizar" medium @click="getInfo">mdi-refresh</v-icon>
                  <v-spacer></v-spacer>
                  <v-btn v-if="canCreate" 
                  color="primary" dark class="mb-2" @click="() => { dialog = true }">Novo Item Laudo Checklist</v-btn>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="prepareDeactivateItem(item)" title="Inativar Item">mdi-delete</v-icon>
              </template>
            </v-data-table>

          </div>
        </v-tab-item>
        <v-tab-item value="naoativos">
          <div class="mt-4">
            <v-card-title v-if="canList">
                Filtro
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisa" single-line
                  hide-details></v-text-field>
              </v-card-title>
            <v-data-table :headers="headers" :items="itensLaudoCheckListInativos" :search="search" sort-by="description" class="border">
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-toolbar-title>Itens Laudo Checklist Inativos</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-icon v-if="canList" title="Atualizar" medium @click="getInfo">mdi-refresh</v-icon>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="prepareActivateItemItem(item)" title="Ativar Item">mdi-undo-variant</v-icon>
              </template>
            </v-data-table>

          </div>

        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <v-dialog v-model="dialog" max-width="800px">
      <v-form>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <validation-provider>
                    <v-text-field class="mb-4" v-model="editedItem.description" :rules="descriptionRules" label="Descrição"
                      name="description" hint="Item Laudo Checklist" persistent-hint />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-checkbox v-model="editedItem.active" label="Ativo" class="ma-0 pa-0" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>

            <v-btn color="blue darken-1" text @click="save">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dialogActivateItem" max-width="500px">
      <v-card>
        <v-card-title>
          <v-card-text>
            <h3 class="transition-swing text-h3 mb--1 error--text">Aviso!</h3>
            <p class="mt-8">Tem certeza que deseja ativar este registro?</p>
          </v-card-text>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="
            dialogActivateItem = false
          itemAtivar = {}
            ">
            Cancelar
          </v-btn>
          <v-btn color="primary" text @click="activateItem()">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeactivateItem" max-width="500px">
      <v-card>
        <v-card-title>
          <v-card-text>
            <h3 class="transition-swing text-h3 mb--1 error--text">Aviso!</h3>
            <p class="mt-8">Tem certeza que deseja inativar este registro?</p>
          </v-card-text>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="
            dialogDeactivateItem = false
          itemDeactivate = {}
            ">
            Cancelar
          </v-btn>
          <v-btn color="primary" text @click="deactivateItem()">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import PatioApi from '@/services/patio/index'
//import { BasicPermissions, Sequences } from '@/utils/permissions'
import { Sequences } from '@/utils/permissions'


export default {

  data() {
    return {
      dialog: false,
      descriptionRules: [(v) => !!v || 'Descrição obrigatória'],
      search: '',
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Descrição', value: 'description' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        proprietario: null,
        active: true,
        description: '',
        data: null,
      },
      defaultItem: {
        proprietario: null,
        active: true,
        description: '',
        data: null,
      },
      dialogDeactivateItem: false,
      itemDeactivate: {},
      dialogActivateItem: false,
      itemAtivar: {},
      tabs: null,
    }
  },
  computed: {
    ...mapState('patio', ['itensLaudoCheckList', 'itensLaudoCheckListInativos']),
    ...mapState('auth', ['empresaAtual']),
    formTitle() {
      return this.editedIndex === -1 ? 'Novo Item Laudo Checklist' : 'Editar Item Laudo Checklist'
    },

    permission() {
      return Sequences.itemLaudoCheckList.toString()
    },

    canList() {
      return true;
      //return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return true;
      //return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
  },

  async created() {
    if (this.canList) {
      this.getInfo()
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  methods: {
    ...mapActions('patio', ['getItensLaudoCheckListParams', 'addItemLaudoCheckList', 'desativarItemLaudoCheckList','ativarItemLaudoCheckList', 'getItensLaudoCheckListInativos',]),

    prepareDeactivateItem(item) {
      this.dialogDeactivateItem = true
      this.itemDeactivate = item
    },

    prepareActivateItemItem(item){
      this.dialogActivateItem = true
      this.itemAtivar = item
    },

    async getInfo() {
      await this.getItensLaudoCheckListParams({
        proprietario: this.empresaAtual.public_id,
      })
      await this.getItensLaudoCheckListInativos({
        proprietario: this.empresaAtual.public_id,
      })
    },

    async deactivateItem() {
      await PatioApi.desativarItemLaudoCheckList(this.itemDeactivate.id)
      this.dialogDeactivateItem = false
      this.getInfo()
    },

    async activateItem(){
      await PatioApi.ativarItemLaudoCheckList(this.itemAtivar.id)
      this.dialogActivateItem = false
      this.getInfo()
    },

    close() {
      this.dialog = false
      setTimeout(() => {
        Object.assign(this.editedItem, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    save() {
      if (this.editedItem.description != null && this.editedItem.description.trim() != '') {
        this.editedItem.data = new Date(Date.now())
        this.editedItem.proprietario = this.empresaAtual.public_id
        this.addItemLaudoCheckList(this.editedItem)
        this.dialog = false
        setTimeout(() => {
          this.getItensLaudoCheckListParams({
            proprietario: this.empresaAtual.public_id,
          })
        }, 300)
      }
    },
  },
}
</script>

